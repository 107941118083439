.tips{
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgb(116 166 217 / 25%);
  border-style: double;
  border-color: #01020a14;
  border-width: 1px;
  padding: 5px;
  margin-bottom: 10px;
  color: #000000c2;
  margin: 10px;
  padding: 10px;
}

.errorMsg{
  color: #fa1e1e;
  font-size: 15px;
  font-weight: bold;
}
.dsBtn:hover{
  background-color: #E0E4ED!important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  color:#4c4c4c!important;
}
.aiStep{
  display:flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.hiddScrollBar::-webkit-scrollbar{
  width:2px!important;
  height: 0px!important;
}