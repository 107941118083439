.container {
  height: 100%;
  border-radius: 8px;
  margin: 0 0px;
}

.tabs:global(.ant-tabs) {
  height: 96%;
  background-color: #fff;
  border-radius: 8px;
}

.tabs {
  padding: 0 20px !important;
}

.tabs :global(.ant-tabs-nav):before {
  display: none;
}

.tabs :global(.ant-tabs-tab) {
  margin-right: 6px !important;
}

.tabs :global(.ant-tabs-nav) {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  height: 40px !important;
  margin: 0 0 20px 0 !important;
}

.tabs :global(.ant-tabs-content) {
  height: 100% !important;
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.containerMask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255,255,255,.8);
  /* opacity: 0.8; */
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerMask span {
  font-size: 14px;
  margin-bottom: 27px;
}

.recipePrintContent {
  width: 100% !important;
  height: 100%;
}

.recipeTypeButtonMain {
  margin-bottom: 30px;
  z-index: 11;
}

.recipeTypeButtonMain :global(.ant-btn-primary) {
  border-radius: 4px !important;
  padding: 4px 8px !important;
  height: 30px !important;
  line-height: 16px !important;
}

.recipeTypeButtonMain :global(.ant-btn-ghost) {
  border-radius: 4px !important;
  padding: 4px 8px !important;
  height: 30px !important;
  line-height: 16px !important;
}

.titleContent {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.title {
  font-weight: bold;
  margin-left: 10px;
  font-size: 16px;
  /* margin-bottom: 20px; */
  color: #101010;
}

.title {
  position: relative;
}

.title::before {
  content: '';
  width: 3px;
  height: 16px;
  position: absolute;
  left: -10px;
  top: 5px;
  background-color: #2d7bff;
}

.leftBottomFormContent {
  /* height: 79%; */
  position: relative;
}

.rowHeight :global(.ant-form-item-label > label) {
  display: flex !important;
}

.rowHeight :global(.ant-form-item-label > label::after) {
  content: '' !important;
}

.radioWidth6 :global(.ant-form-item-label > label) {
  display: flex !important;
}

.radioWidth6 :global(.ant-form-item-label > label::after) {
  content: '' !important;
}

.radioWidth12 :global(.ant-form-item-label > label) {
  display: flex !important;
}

.radioWidth12 :global(.ant-form-item-label > label::after) {
  content: '' !important;
}

.radioWidth6Print :global(.ant-form-item-label > label) {
  display: flex !important;
}

.radioWidth6Print :global(.ant-form-item-label > label::after) {
  content: '' !important;
}

.rowHeight :global(.ant-checkbox + span) {
  width: 170px !important;
}

.radioWidth6 :global(.ant-checkbox + span) {
  width: 170px !important;
}

.radioWidth6 :global(span.ant-radio + *) {
  width: 172px !important;
}

.radioWidth12 :global(span.ant-radio + *) {
  width: 260px !important;
}

.radioWidth6Print :global(span.ant-radio + *) {
  width: 100% !important;
}

.rowHeight :global(.ant-checkbox-disabled + span) {
  color: #000000d9 !important;
}

.radioWidth6 :global(.ant-checkbox-disabled + span) {
  color: #000000d9 !important;
}

.leftContent {
  flex: 1;
  overflow-y: scroll;
  height: 90%;
  overflow-x: hidden;
}

.rightContent {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  width: 610px;
  height: 99%;
  overflow: auto;
}

.rowHeight {
  min-height: 36px;
  display: flex !important;
  align-items: center !important;
  z-index: 99 !important;
}

.leftBottomFormContent :global(.ant-form-item-control-input) {
  min-height: 36px !important;
}

.nameTips {
  color: #2d7bff;
  margin-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.leftBottomFormContent :global(.ant-form-item) {
  margin-bottom: 0 !important;
}

.alignItemsCenter {
  display: flex !important;
  align-items: center !important;
}

.center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rightContentTitle {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  position: relative;
}

.rightContentMain {
  background-color: #ffffff;
  margin: 0 15px 0 15px;
  height: 720px;
  padding: 26px 20px;
  position: relative;
}

.rightContentMainBL {
  background-color: #ffffff;
  margin: 0 15px 0 15px;
  height: 780px;
  padding: 24px 20px;
  position: relative;
}

.rightContentJSXPMessage4017 {
  height: 330px;
  padding: 12px;
  margin: 0 15px 0 15px;
  background-color: #ffffff;
  position: relative;
}

.rightContentMZFPMessage {
  height: 370px;
  padding: 12px;
  margin: 0 15px 0 15px;
  background-color: #ffffff;
  position: relative;
}

.rightContentMainCenterContentSmallRecipeJSXP240217 {
  width: 100%;
  height: 330px;
  padding: 0 20px 10px 20px;
  position: relative;
  background-color: #ffffff;
}

.titleButton {
  position: absolute;
  right: 8px;
  display: flex;
}

.titleButton :global(.ant-btn-primary) {
  border-radius: 4px !important;
  padding: 4px 8px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
}

.rightContentBottom {
  margin: 15px;
  color: #101010;
}

.rightContentBottomSmallRecipe {
  margin: 15px;
  color: #101010;
  position: absolute;
  bottom: 0;
}

.tenantNameTitle {
  width: 100%;
  font-size: 20px;
  color: #101010;
  display: flex;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.subTitleMain {
  display: flex;
  justify-content: space-between;
}

/* 小票 */
.rightContentXP {
  /* min-height: 50%; */
  margin: 0 15px 0 15px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightContentXPMessage {
  width: 320px;
  height: 486px;
  padding: 26px;
  background-color: #ffffff;
}

.rightContentDJTMessage {
  width: 300px;
  height: 210px;
  padding: 13px 15px;
  background-color: #ffffff;
}

.rightContentPTMessage {
  width: 300px;
  height: 210px;
  padding: 13px 0;
  background-color: #ffffff;
}

.rightContentDJT8060Message {
  width: 400px;
  padding: 13px 15px;
  background-color: #ffffff;
}

.rightContentPT8060Message {
  width: 400px;
  padding: 13px 0px;
  background-color: #ffffff;
}

.DJTMessageMain {
  margin-top: 12px;
  border: 1px solid #333;
  height: 78%;
}

.YHTMessageMain {
  border: 1px solid #333;
  margin-top: 6px;
}

.DJTMessageMainTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #333;
  height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.XPMessageMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 10px 0;
  font-size: 12px;
}

.DLXPMessageMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 10px 0;
  font-size: 12px;
}

.patientInfoMain {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 7px;
  border-bottom: 1px solid #333;
}
/* 单联挂号小票 */
.XPMessageMain > img {
  width: 110px;
  height: 46px;
}

.rightContentDLXP {
  width: 320px;
  height: 290px;
  padding: 20px;
  background-color: #ffffff;
}

/* 双联挂号小票 */
.rightContentSLXPMain {
  width: 320px;
  height: 290px;
  padding: 20px;
  display: flex;
  background-color: #ffffff;
}

/* 结算小票 */
.rightContentJSXPMessage {
  width: 320px;
  padding: 16px 24px;
  background-color: #ffffff;
  position: relative;
}

.JSXPMessageMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 10px 0;
  font-size: 12px;
}

.XPContent {
  display: flex;
  flex-direction: column;
}

.subTitleMainLeftTop > div > img {
  width: 50px;
  height: 27px;
}

.subTitleMainLeftTop > div > div {
  font-size: 10px;
}

.subTitleMainCenter {
  font-size: 24px;
  font-weight: bold;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.subTitleMainRight {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.subTitleMainRightNoBorder {
  width: 40px;
  height: 40px;
}

.subTitleMainRightBorder {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(51, 51, 51, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paymentGZStyle {
  width: 56px;
  height: 56px;
  border: 1px solid #9d9d9d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: -14px;
}

.TextStyle {
  display: flex !important;
  width: 100%;
}

.titleTwoTextStyle {
  width: 38px;
  font-size: 12px;
}

.titleThreeTextStyle {
  width: 50px;
  font-size: 12px;
}

.titleFourTextStyle {
  width: 62px;
  font-size: 12px;
}

.titleFiveTextStyleBold {
  width: 72px;
  font-size: 12px;
  font-weight: bold;
}

.valueTextStyle {
  flex: 1;
  /* width: 71px; */
  font-size: 12px;
  /* margin-right: 10px; */
  border-bottom: 1px solid #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.valueTextStyleNoBorder {
  flex: 1;
  /* width: 71px; */
  font-size: 12px;
  /* margin-right: 10px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.TextStyleLine {
  margin-bottom: 4px;
}

.recipeContentList {
  margin-top: 4px;
}

.rpsStyle {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
   margin-bottom: 2px;
}

.cfmxStyle{
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 4px;
}

.recipeZYList {
  display: flex;
  flex-wrap: wrap;
}

.recipeXYListOneLIneMain {
  display: flex;
  justify-content: space-between;
}

.recipeXYListOneLIneMainLeft {
  width: 86%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
}

.recipeSZZXDListOneLIneMainLeft {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  line-height: 30px;
}

.recipeXYListOneLIneMainRight {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
}

.recipeXYListTwoLIneMain {
  display: flex;
  font-size: 13px;
  margin-left: 16px;
}

.recipeXYListTwoLIneMainLeft,
.recipeXYListTwoLIneMainCenter,
.recipeXYListTwoLIneMainRight {
  width: 20%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.recipeXYListThreeLIneMain {
  display: flex;
  margin-left: 16px;
}

.recipeXYListThreeLIneMainLeft {
  width: 48%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.recipeXYListThreeLIneMainRight {
  flex: 1;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.borderBottomStyle {
  border-bottom: 1px dashed #bbbbbb;
  /* margin-top: 8px; */
}

.borderBottomSolidStyle {
  border-bottom: 1px solid #000000;
}

.doctorContent {
  width: 19%;
  display: flex;
  margin-right: 4px;
  margin-top: 6px;
}

.doctorBorder {
  flex: 1;
  border-bottom: 1px solid rgba(51, 51, 51, 1);
  display: flex;
  justify-content: center;
}

.doctorContentTitle {
  width: 44px;
}

.doctorTips {
  display: flex;
  justify-content: center;
  color: #707070;
  font-size: 12px;
  margin-top: 8px;
}

.footerContent {
  width: 92%;
  position: absolute;
  bottom: 10px;
}

.treatmentPrintTable {
  width: 100%;
  border: 1px solid #dbdbdb;
}
.treatmentPrintTable > th {
  border: 1px solid #dbdbdb;
}

.treatmentPrintTable th {
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
  text-align: center;
  height: 20px;
}

.treatmentPrintTable td {
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
  text-align: center;
  height: 30px;
}

.treatmentPrintTable .tableRightBottom:last-child {
  border-bottom: none;
}

.treatmentPrintTable {
  max-height: 88%;
}

.treatmentPrintContents {
  max-height: 88%;
  overflow: auto;
}

.treatmentPrintContentScroll {
  height: 100px;
  overflow: auto;
}

.borderRight {
  border-right: 1px solid #000000;
  margin-bottom: 8px;
}

.lineHeightContent {
  line-height: 28px;
}

.lineHeightContentBold {
  font-weight: bold;
  line-height: 28px;
}

.JSXPContentLineHeight {
  line-height: 22px;
}

.YLBXJSDLineHeight {
  line-height: 16px;
}

.ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imgs {
  width: 62px;
  height: 59px;
}

.borderSolidWidth {
  width: 258px;
  border-bottom: 1px solid #000000;
  margin: 8px 0;
}

.borderTextStyle {
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xpPrintTable {
  width: 100%;
  border: 1px solid #dbdbdb;
  font-size: 11px;
  font-weight: normal;
  margin-top: 6px;
}
.xpPrintTable > th {
  border: 1px solid #dbdbdb;
}

.xpPrintTable th {
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
  text-align: center;
  height: 20px;
}

.xpPrintTbody > tr > td {
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
  text-align: center;
  height: 20px;
}

.xpPrintTfoot > tr > td:nth-of-type(1) {
  border-right: 1px solid #dbdbdb;
}

.xpPrintTable {
  max-height: 88%;
}

.xmmxdFooter {
  font-size: 9px;
  padding: 0 15px;
  position: absolute !important;
  bottom: 6px;
}

.radio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #999999;
  margin-right: 6px;
}

.rulesStyle :global(.ant-form-item-explain-error) {
  width: 134px !important;
}

.radioWidth6 :global(.ant-input-group-addon) {
  background-color: #fff !important;
  border: none !important;
}

.radioWidth6 :global( .ant-input-group-addon) {
  color: #333 !important;
}
