@value colors: "../../../colors.css";
@value primary-color, text-color-1, table-header-bg-color from colors;
.wrap {
  padding: 0 10px;
}

.fl {
  display: flex;
  align-items: center;
}

/* 头部区域样式 */
.headerWrap {
  width: 100%;
  height: 100%;
}

.headerContent {
  margin: 10px 0;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
}

.rightContent {
  width: 68%;
}

.headerContentNumMain {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.headerContentIcon {
  font-size: 24px;
}

.headerContentNum {
  /* width: 74px; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  font-weight: 500;
}

.headerContentText {
  color: #9fa19f;
}

/* 内容区域样式 */
.cetnerWrap {
  display: flex;
  height: calc(100vh - 160px);
}

/* 左侧菜单栏区域样式 */
.leftWrap {
  height: 100%;
  width: 200px;
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 40px;
  cursor: pointer;
}

.leftContent:hover {
  background-color: red;
}

.leftContentText,
.leftContentNum {
  font-size: 15px;
  font-weight: 600;
}

.leftContentNum {
  color: #687cf6;
}

/* 右侧内容区域样式 */
.rightWrap {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  border-radius: 3px;
}

/* .rightWrap {
  margin-left: 10px;
  padding-top: 10px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  overflow: auto;
} */

/* 底部区域样式 */
.footerWrap {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.queryWrap {
  display: flex;
  justify-content: space-between;
}

.table {
  margin-top: 10px;
  height: calc(100% - 160px);
  padding-left: 10px;
}

.messageTable {
  margin-top: 10px;
  height: calc(100% - 160px);
  padding-left: 10px;
}

.messageTableNew {
  margin-top: 10px;
  height: auto;
  max-height: calc(100% - 160px);
  padding-left: 10px;
}

.info {
  color: rgba(255,27,27,1);
  font-size: 14px;
  padding-left: 10px;
  padding-top: 20px;
}

.workStationPageTable {
  width: 100%;
  /* padding-left: 10px; */
  /* overflow: hidden; */
}
.workStationPageTable :global(.ant-spin-nested-loading),
.workStationPageTable :global(.ant-spin-container),
.taworkStationPageTableble :global(.ant-table),
.workStationPageTable :global(.ant-table-container),
.workStationPageTable :global(.ant-table-content) {
  height: 100%;
}

.workStationPageTable :global(.ant-table-content) {
  overflow: auto !important;
}
.workStationPageTable :global(.ant-table-body) {
  height: 100%;
  /* overflow: none !important; */
  /* background-color: red; */
}

.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(1)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(2)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(3)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(4)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(5)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(6)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(7)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(8)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(9)) {
  padding: 0 !important;
}
.workStationPageTable :global(.ant-table-thead > tr > th:nth-child(10)) {
  padding: 0 !important;
}
/* 挂号弹窗 */
.modalWrap {
  height: 628px;
  overflow: auto;
  padding-right: 10px;
}
.modalWrapAddPatient {
  height: 610px;
  overflow: auto;
  padding-right: 10px;
}
.modalWrapAddPatient .labelRow:global(.ant-row) {
  max-height: 80px;
  height: auto !important;
}

.modalWrapAddPatient .labelRow :global(.ant-row) {
  max-height: 80px;
  height: auto !important;
}

.modalWrapAddPatient .labelRow :global(.ant-form-item) {
  margin-bottom: 15px;
}
.modalWrapAddPatient :global(.ant-row) {
  height: 45px !important;
}
.modalWrapAddPatient :global(.ant-modal-body) {
  padding: 20px !important;
}
.modalWrap :global(.ant-modal-body) {
  padding: 20px !important;
}

.modalHeaderWrap {
  margin-bottom: 16px;
  padding: 10px 20px;
  height: 100px;
  background-color: #f5f5f5;
}

.modalHeaderWraps {
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 100px;
  background-color: #f5f5f5;
}

.modalHeaderWrapsYBContent{
   margin-bottom: 10px;
  padding: 10px 20px;
  height: 132px;
  background-color: #f5f5f5;
}

.modalHeaderWrapsYBContent :global(.ant-row){
  height:30px !important
}
/* 头部信息 */

.headerUserWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.icon {
  /* font-size: 28px;
  width: 48px;
  text-align: center;
  border: 1px solid #999999;
  background-color: #999999;
  border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.user {
  margin: 0 10px;
}

.money {
  color: #ff5553;
}

.num {
  color: #737673;
}

.remark {
  width: 280px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* form 表单 */
.tyleZIFEI {
  margin-right: 20px;
  color: #2a7cf6;
  border-bottom: 2px solid #2a7cf6;
  cursor: pointer;
}

.tyleZIFEIActive {
  margin-right: 20px;
  cursor: pointer;
}

/* 医生 */
.doctorWrap {
  display: flex;
  width: 50px;
  margin-top: 10px;
}

.doctorCardWrap {
  /* margin-bottom: 20px; */
  padding: 10px 10px 10px 10px;
  background-color: #f4f8fb;
}

.itemWrap,
.itemActiveWrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.09);
}

.itemActiveWrap {
  background-color: #d8e8ff;
  border: 1px solid #0076ff;
}

.itemUser {
  display: flex;
  justify-content: space-between;
}

.itemIcon {
  margin-right: 10px;
  color: #2a7cf6;
}

.itemName {
  width: 4.5rem;
  height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.itemDepartment {
  width: 4.5rem;
  height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  text-align: center;
}

.itemNum {
  color: #357cf6;
  cursor: pointer;
}
.itemActiveIcon {
  position: absolute;
  top: -7px;
  right: -6px;
  color: #0076ff;
}
.stopAction {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 50px;
}

/* 新增患者弹窗 */
.caseNumWrap {
  margin: 0 10px 20px 0;
  display: flex;
  justify-content: end;
  width: 21%;
}
.caseNumWrapEdit {
  margin: 0 10px 20px 0;
  display: flex;
  justify-content: end;
  width: 33%;
}
.footerBtnWrap {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: end;
  /* position: fixed;
  bottom:10px */
}

/* 预检分诊-新增预检分诊弹窗 */
.addPreviewingModalWrap {
  height: 600px;
  overflow: auto;
}

/* 收费-底部按钮 */
.chargeFooter {
  margin-bottom: 20px;
}
/* 收费-划价弹窗 */
.cutPriceModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 40px;
}
.footerText {
  font-weight: 600;
}

/* 患者生日-发送短信弹窗 */
.sendModalWrap {
  display: flex;
  height: 450px;
  overflow: auto;
}
.sendModalWrap :global(.ant-tree .ant-tree-node-content-wrapper) {
  width: 175px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.templates :global(.ant-collapse-header) {
  margin-bottom: 10px;
  color: text-color-1 !important;
  font-size: 1.125rem;
  height: 3rem !important;
  min-height: 3rem !important;
  background-color: table-header-bg-color;
  border-left: 2px solid primary-color;
  display: flex;
  flex: 1;
  position: relative;
}

.templates :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  background-color: #ebf3ff !important;
}
.templates :global(.ant-collapse-content) {
  background-color: #fff !important;
}
.templates :global(.ant-collapse-item) {
  border: none !important;
}
.sendCenterWrap {
  margin: 0 20px;
  width: 332px;
}
.centerText {
  margin-bottom: 20px;
  font-weight: 600;
}

.messageImg {
  position: relative;
  width: 230px;
  height: 350px;
  background: url('./modal/img/phone.png') no-repeat;
  background-size: 100% 100%;
}
.messageImgInfo {
  border-radius: 8px;
  box-sizing: border-box;
  position: absolute;
  top: 60px;
  left: 33px;
  width: 162px;
  padding: 5px 10px;
  background-color: #deddef;
}

.sendModalFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* 表格省略号 */
.tableText {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 4.5rem;
  text-align: center;
  margin-right: 5px;
}

.textName {
  margin: 4px 2px 2px 0;
  padding: 2px;
  height: 19px;
  width: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.tableIcon {
  cursor: pointer;
  color: #0f0f0f;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activeTableIcon {
  cursor: not-allowed;
  color: #ccc;
  font-size: 19px;
  height: 100%;
  display: flex;
  align-items: center;
}

.sendMessageModal {
  overflow: hidden !important;
}

.patientInfo {
  display: flex;
  align-items: center;
  justify-content: start;
}
.patientAvrter {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  /* background-color: #999; */
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #fff;
  margin-right: 15px;
}

.cellItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  padding-left: 25px;
  position: relative;
  margin: 2px;
}

.cellItem::before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  border: 1px solid #1dd127;
}

.cellTitle {
  cursor: default;
  margin: 5px 0;
}

.flex {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.table :global(.ant-spin-container) {
  /* min-width: 1366px !important; */
  min-width: 1546px !important;
}

.messageTable:global(.ant-spin-container) {
  width: 600px !important;
}

.modalDisease {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  background-color: #f4f8fb;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* 收费信息合计行样式 */
.table :global(.ant-table-cell-fix-right-first):nth-of-type(10) {
  background-color: #d9f2ff !important;
}

.total {
  background-color: #d9f2ff !important;
}

.RegisteredModal :global(.ant-modal-body) {
  padding: 12px 24px 0 24px !important;
}

.RegisteredModal :global(.ant-form-item) {
  margin-bottom: 12px !important;
}

.addPatientModal :global(.ant-input-disabled) {
  color: #333 !important;
}

.birthdayStyle :global(.ant-form-item-explain-error){
position: absolute !important;top:0 !important;right:0 !important
}

.messageModal :global(.ant-modal-body) {
  padding: 10px;
}