.putStorage {
    height: calc(100% - 5px);
    overflow-y: scroll;
    border-radius: 10px;
    margin: 10px 0;
}

.head {
    background-color: #ffffff;
    margin-left: 19px;
    margin-right: 21px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 3px;
    padding-bottom: 5px;
    position: relative !important;
    height: 38px;
    box-shadow: 0px 2px 0px 0px #027aff;
}

.add {
    position: absolute !important;
    right: 20px !important;
}

.content {
    height: calc(100% - 210px);
    margin: 10px 20px;
    padding: 10px 20px;
    background: #ffffff;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}

.content :global(.ant-table-content) {
    height: calc(70% - 100px) !important;
    overflow: inherit !important;
}

.table {
    height: calc(70% - 100px);
    overflow: hidden;
    font-size: 14px;
}

.table th {
    padding: 0 3px !important;
}

.table td {
    padding: 0 3px !important;
}

.titletext {
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px #f3f7fd;
    width: 100%;
    color: #333333;
}

.box :global(.ant-popover-buttons) {
    display: none;
}

.box :global(.ant-popover-inner) {
    width: 160px;
    height: 65px;
    line-height: 20px;
}

.box :global(.ant-popover-inner-content) {
    padding: 0 !important;
}

.box :global(.ant-popover-message-title) {
    padding-left: 0 !important;
}

.iconWrap {
    position: absolute;
    right: 3px;
    top: 0;
    font-size: 15px;
    cursor: pointer;
}

.form {
    width: 1240px !important;
}

.footer {
    margin: 12px 20px;
    height: calc(100% - 70% - 81px);
    margin: 12px 20px;
    padding: 14px 20px 10px 20px;
    background: #fff;
    border-radius: 10px;
}

.footer :global(.ant-form-item) {
    margin-bottom: 6px !important;
}

.contentTitle :global(.ant-form-item) {
    margin-bottom: 6px !important;
}

.lable:global(.ant-col .ant-form-item .ant-form-item-label > label) {
    font-size: 18px;
}

.Qingdou {
    height: calc(100% - 40px);
    width: 100%;
    margin-left: -2px;
    /* margin-top: -30px; */
}

.upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload :global(.Icons_large__14hGG) {
    width: 4rem !important;
    height: 4rem !important;
    fill: #b8bcc5 !important;
}

.main :global(.ant-modal-title) {
    text-align: center;
}

.main {
    position: relative;
}

.imgWrap {
    position: relative;
    margin: 0 0 20px 20px;
    width: 100px;
    height: 100px;
    user-select: none;
}

.img {
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.imgClose {
    position: absolute;
    right: -9px;
    top: -10px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    font-size: 15px;
    color: #fff;
    background-color: #999999;
    border-radius: 50%;
    cursor: pointer;
}

.menu:hover {
    color: #fff !important;
    background-color: #027aff !important;
}

.overlay :global(.ant-popover-inner-content),
.overlay-hover :global(.ant-popover-inner-content) {
    padding: 0 !important;
}

.overlay-hover :global(.ant-popover-arrow) {}

.bradeContent :global(.ant-badge) {
    display: inline !important;
}

.total {
    background-color: #d9f2ff;
}

/* .bradeContent :global(.ant-badge-count) {
  margin-top: 4px !important;
} */